import { Box, IconButton, Typography } from "@mui/material";
import { Colors } from "../utils/Colors";
import { Progreso } from "./Avance";
import { Download } from "@mui/icons-material";
import { handleDownloadExcel } from "../utils/common";

export const AvancePorInstitucion = ({
  institucion,
  totalBombas,
  bombasInventariadas,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        borderRadius: ".5rem",
        border: "2px solid " + Colors.primary,
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding=".5rem"
        sx={{
          width: "100%",
          backgroundColor: Colors.primary,
          color: "white",
        }}
      >
        <Typography variant="subtitle2">{institucion}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" padding="1rem" gap=".5rem">
        <Box
          display="flex"
          sx={{
            borderBottom: "1px solid " + Colors.primary,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2">
            <strong>Total de bombas:</strong> {totalBombas.length}
          </Typography>

          <IconButton
            title="Descargar Total de Bombas"
            onClick={(e) =>
              handleDownloadExcel(
                totalBombas,
                `${institucion} - Total de Bombas`,
              )
            }
            sx={{
              color: Colors.primary,
            }}
          >
            <Download />
          </IconButton>
        </Box>
        <Box
          display="flex"
          sx={{
            borderBottom: "1px solid " + Colors.primary,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2">
            <strong>Bombas inventariadas:</strong> {bombasInventariadas.length}
          </Typography>
          <IconButton
            title="Descargar Bombas Inventariadas"
            onClick={(e) =>
              handleDownloadExcel(
                bombasInventariadas,
                `${institucion} - Bombas Inventariadas`,
              )
            }
            sx={{
              color: Colors.primary,
            }}
          >
            <Download />
          </IconButton>
        </Box>

        <Box
          display="flex"
          sx={{
            borderBottom: "1px solid " + Colors.primary,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2">
            <strong>Bombas restantes:</strong>{" "}
            {totalBombas.length - bombasInventariadas.length}
          </Typography>
          <IconButton
            title="Descargar Bombas Restantes"
            onClick={(e) =>
              handleDownloadExcel(
                totalBombas.filter(
                  (bomba) =>
                    !bombasInventariadas.some((b) => b.serie === bomba.serie),
                ),
                `${institucion} - Bombas Restantes`,
              )
            }
            sx={{
              color: Colors.primary,
            }}
          >
            <Download />
          </IconButton>
        </Box>

        <Progreso
          avance={(bombasInventariadas.length * 100) / totalBombas.length}
        />
      </Box>
    </Box>
  );
};
